import "./intro.css";


const Intro = () => {
    return (
        
        <div className="intro">
            <div className="intro-left">
                <div className="intro-left-wrapper">
                    <h2 className="intro-intro">Hello, my name is</h2>
                    <h1 className="intro-name">Oscar Fenn</h1>
                    <div className="intro-title">
                    <div className="intro-title-wrapper">
                        <div className="intro-title-item">Web Designer</div>
                        <div className="intro-title-item">Front End Developer</div>
                        <div className="intro-title-item">Creative</div>
                    </div>
                    </div>
                    <p className="intro-description">
                    Hey, my name is Oscar Fenn and I'm from Orlando, Florida. 
                    I'm a UCF graduate with a Bachelors in Digital Media with Specialization in Web Design.
                    </p>
                </div>
                <a class="intro-scroll" href="#about"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM390.6 246.6l-112 112C272.4 364.9 264.2 368 256 368s-16.38-3.125-22.62-9.375l-112-112c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L256 290.8l89.38-89.38c12.5-12.5 32.75-12.5 45.25 0S403.1 234.1 390.6 246.6z"/></svg></a>
            </div>
            <div className="intro-right">
            <div className="intro-bg"></div>
                {/*<img src={Me} alt="" className="intro-img"/>*/}
            </div>
        </div>
    )
}

export default Intro