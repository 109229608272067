import "./productList.css";
import Product from "../product/Product";
import {products} from "../../data";

const ProductList = () => {
    return (
        <div className="pl">
        <div className="pl-texts">
        <h1 className="pl-title">Portfolio</h1>
        <p className="pl-desc">These are my web design projects. They are all a mix of work projects and personal projects that I created to practice my design skills. All my web development projects use HTML, CSS, and Javascript.</p>
        </div>
        <div data-aos="fade-down" className="pl-list">
        {products.map((item) => (
            <Product key={item.id} img={item.img} link={item.link} text={item.text} github={item.github}/>
        ))}
        
        </div>
        </div>
    )
}

export default ProductList