import "./about.css"
import Me from "../../img/IMG_8074.jpg";

const About = () => {
    return (
        <div id="about" className="about">
            <div data-aos="fade-right" className="about-left">
                <div className="about-card bg"></div>
                <div className="about-card">
                    <img src={Me} alt="" className="about-img" />
                </div>
            </div>
            <div data-aos="fade-left" className="about-right">
                <h1 className="about-title">About Me</h1>
                <p className="about-subtitle">Hey, my name is Oscar Fenn and I'm from Orlando, Florida. I'm a UCF graduate with a Bachelors in Digital Media with Specialization in Web Design. 
                I'm currently a Full-Time Web Designer working at the Institute for Intergovernmental Research.</p>
                <p className="about-desc">On my free time, I like to play video games, skateboard, produce music, hang out with my friends and travel! I'm experienced with HTML, CSS, Javascript, PHP, Adobe Creative Suite, and many more. 
                I'm also experienced with Bootstrap and I also have experience with prototyping, wireframes, and user interface design.</p>
                <div className="about-award">
                    <img src="" alt="" className="a-award-img" />
                </div>
            </div>
        </div>
    )
}

export default About