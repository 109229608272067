
export const products = [
    {
      id: 1,
      img: "https://i.ibb.co/0KPhwLy/oayi.png",
      link: "https://www.opioidaffectedyouth.org/",
      text: "Website created for OAYI using HTML, CSS, JavaScript, and Bootstrap.",
    },
    {
      id: 2,
      img: "https://i.ibb.co/WfShgMP/screencapture-radiant-yeot-751978-netlify-app-2022-10-28-15-12-07.jpg",
      link: "https://radiant-yeot-751978.netlify.app/",
      text: "Weather App made with vanilla Javascript and uses the OpenWeatherMap API to show weather information for any city. Also using Unsplash to show images related to each city that is searched.",
      github: "https://github.com/OscarFenn/WeatherApp"
    },
    {
      id: 3,
      img: "https://i.ibb.co/6XVf47g/e6e6d673491871-5c0ae536cc86b.png",
      link: "https://www.behance.net/gallery/73491871/Prototyping-Class-Project",
      text: "Class project where we had to create a website redesign by making a prototype in Adobe XD.",
      
    },
    {
      id: 4,
      img: "https://i.ibb.co/yqqcrPK/screencapture-strong-malabi-7e37c4-netlify-app-store-2022-10-28-14-50-13.jpg",
      link: "https://strong-malabi-7e37c4.netlify.app/",
      text: "Shopping cart React project styled with Material UI. You can add items to the shopping cart and the total amount is calculated. The number of items is also counted in the cart and duplicate items are counted as well. The cart items also save to localstorage.",
      github: "https://github.com/OscarFenn/Shopping-cart"
    },
    {
      id: 5,
      img: "https://i.ibb.co/NF7QsrT/screencapture-behance-net-gallery-74838675-Internship-Redesign-2022-04-30-23-08-10.png",
      link: "https://www.behance.net/gallery/74838675/Internship-Redesign",
      text: "Redesigned company website using Wordpress.",
    },
    {
      id: 6,
      img: "https://i.ibb.co/Wf1tPxv/screencapture-scintillating-bublanina-596e7d-netlify-app-2022-05-23-01-19-45.png",
      link: "https://scintillating-bublanina-596e7d.netlify.app/",
      text: "Japanese quiz web app using ReactJS and Tailwind CSS. It tracks streak and highest streak and starts streak over when wrong answer is given.",
      github: "https://github.com/OscarFenn/JapaneseQuiz"
    },
  ];