import "./contact.css";

import Email from "../../img/email.png";
import Address from "../../img/address.png";
import {useRef, useState} from "react";
import emailjs from '@emailjs/browser';
import {ThemeContext} from "../../context"
import { useContext } from "react";

const Contact = () => {
    const formRef = useRef();
    const [done,setDone] = useState(false);
    const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

    const handleSubmit = (e)=>{
        e.preventDefault();

        emailjs.sendForm('service_ajsmt9d', 'template_e3mza5l', formRef.current, 'HV76VDgLk7ETPSsDK')
      .then((result) => {
          console.log(result.text);
          setDone(true)
      }, (error) => {
          console.log(error.text);
      });
    }
    return (
        <div className="contact">
            <div className="contact-bg"></div>
            <div className="contact-wrapper">
                <div data-aos="fade-right" className="contact-left">
                    <h1 className="contact-title">
                        Let's get connected
            </h1>
                    <div className="contact-info">
                        {/*<div className="contact-info-item">
                            <img src={Phone} alt="" className="contact-icon" />
                            407-373-3528
    </div>*/}
                        <div className="contact-info-item">
                            <img src={Email} alt="" className="contact-icon" />
                            oscar.fennjr@gmail.com
                        </div>
                        <div className="contact-info-item">
                            <img src={Address} alt="" className="contact-icon" />
                            Orlando, Florida
                        </div>
                        <div className="contact-info-item">
                        <ul>
                        <li>
    <a href="https://www.linkedin.com/in/oscar-fennjr/"><i class="fab fa-linkedin-in icon"></i></a>
    </li>
    </ul>
                        </div>
                    </div>
                </div>
                <div data-aos="fade-left" className="contact-right">
                <p className="contact-desc">
                <b>Like my creative works?</b> Get in touch.
                </p>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Name" name="user_name"/>
                    <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Subject" name="user_sub"/>
                    <input style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Email" name="user_email"/>
                    <textarea style={{backgroundColor: darkMode && "#333"}} rows="5" placeholder="Message" name="message"/>
                    <button>Submit</button>
                    {done && "Thank you for the message"}
                </form>
                </div>
            </div>

        </div>
    )
}

export default Contact