import {useContext} from "react";
import Intro from "./components/intro/intro";
import About from "./components/about/About";
import ProductList from "./components/productList/ProductList";
import Contact from "./components/contact/Contact";
import Toggle from "./components/toggle/Toggle"
import { ThemeContext } from "./context";
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  
  return ( 
  <div style={{backgroundColor:darkMode ? "#222" : "white",
  color: darkMode && "white",
  }}>
    <Intro/>
    <About/>
    <ProductList/>
    <Contact/>
    <Toggle/>
  </div>
  );
};
AOS.init();
export default App;